import React from 'react'
import { TableSection, TableContainer, Table } from './styles'
import tw, { css } from 'twin.macro'

const EcoClearTable = ({ locale }) => {
	return (
		<TableSection>
			<TableContainer>
				<Table>
					{locale === 'en-AU' ? (
						<tbody>
							<tr>
								<th>
									<strong>Filter</strong>
								</th>
								<th>
									<strong>Fine Grade</strong>
								</th>
								<th>
									<strong>Coarse Grade</strong>
								</th>
							</tr>
							<tr>
								<td className="subhead">
									<strong>(Inches)</strong>
								</td>
								<td className="subhead">
									<strong>Weight</strong>
								</td>
								<td className="subhead">
									<strong>Weight</strong>
								</td>
							</tr>
							<tr>
								<td>20 inches</td>
								<td>45 kg</td>
								<td>30 kg</td>
							</tr>
							<tr>
								<td>24 inches</td>
								<td>90 kg</td>
								<td>30 kg</td>
							</tr>
							<tr>
								<td>25 inches</td>
								<td>90 kg</td>
								<td>45 kg</td>
							</tr>
							<tr>
								<td>26 inches</td>
								<td>90 kg</td>
								<td>45 kg</td>
							</tr>
							<tr>
								<td>28 inches</td>
								<td>105 kg</td>
								<td>45 kg</td>
							</tr>
							<tr>
								<td>30 inches</td>
								<td>120 kg</td>
								<td>45 kg</td>
							</tr>
							<tr>
								<td>32 inches</td>
								<td>150 kg</td>
								<td>60 kg</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							<tr>
								<th>
									<strong>Filter</strong>
								</th>
								<th>
									<strong>Fine Grade</strong>
								</th>
								<th>
									<strong>Coarse Grade</strong>
								</th>
							</tr>
							<tr>
								<td className="subhead">
									<strong>(Inches)</strong>
								</td>
								<td className="subhead">
									<strong>Weight</strong>
								</td>
								<td className="subhead">
									<strong>Weight</strong>
								</td>
							</tr>
							<tr>
								<td>20 inches</td>
								<td>45 kg</td>
								<td>30 kg</td>
							</tr>
							<tr>
								<td>24 inches</td>
								<td>90 kg</td>
								<td>30 kg</td>
							</tr>
							<tr>
								<td>25 inches</td>
								<td>90 kg</td>
								<td>45 kg</td>
							</tr>
							<tr>
								<td>26 inches</td>
								<td>90 kg</td>
								<td>45 kg</td>
							</tr>
							<tr>
								<td>28 inches</td>
								<td>105 kg</td>
								<td>45 kg</td>
							</tr>
							<tr>
								<td>30 inches</td>
								<td>120 kg</td>
								<td>45 kg</td>
							</tr>
							<tr>
								<td>32 inches</td>
								<td>150 kg</td>
								<td>60 kg</td>
							</tr>
						</tbody>
					)}
				</Table>
				<p css={tw`mt-4`}>
					EcoClear Fine and Coarse Glass Filter Media are sold as 20kg bags.
				</p>
			</TableContainer>
		</TableSection>
	)
}

export default EcoClearTable
